import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './css/index.css';
import 'flowbite';
import Navigation from './components/navtest';
import MainMedia from './components/MainMedia';
import ProductView from './components/ProductView';
import BottomBar from './components/BottomBar';
import AnnounceView from './components/AnnounceView';
import ContactView from './components/ContactView';
import ContactManageView from './components/ContactManageView';

const Main = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    return (
        <>
            <div className="bg-[#020202] z-10 mt-11">
                <Navigation/>
                <Routes>
                    <Route path="/" element={<MainMedia />}/>
                    <Route path="/products" element={<ProductView />}/>
                    <Route path="/announce" element={<AnnounceView />}/>
                    <Route path="/contact" element={<ContactView MessageText={searchParams.get('msg')} />}/>
                    <Route path="/admin/contact_manage" element={<ContactManageView />}/>
                </Routes>
                <BottomBar/>
            </div>
        </>
    )
}

export default Main;