import React from "react";
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../assets/logo_dang.png';

const navigation = [
  { name: '메인', href: '/', current: true },
  { name: '제품소개', href: '/products', current: false },
  { name: '견적문의', href: '/contact', current: false },
  { name: '공지사항', href: '/announce', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navigation() {
  return (
    <>
      <Disclosure as="nav" className="drop-shadow-sm bg-[#000000] z-40" style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-[70px] items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                 
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-[#111827]">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="/">
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={logo}
                        alt="logo"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={logo}
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div className="hidden sm:ml-6 sm:block ">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                          item.current ? 'text-white' : 'text-[#cfcfcf] hover:text-white',
                            'rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-[#59825D] text-white' : 'text-[#434448] hover:bg-[#718F74] hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
            <div className="w-full h-[1px] bg-[#1E1E1E]"></div>
          </>
        )}
      </Disclosure>
    </>

  )
}