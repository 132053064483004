import { useState, useEffect, useRef } from 'react';

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);
  
    useEffect(() => {
        const handleResize = () => {
          const windowHeight = window.innerHeight;
          setWrapperHeight(windowHeight - 70);
        };
      
        handleResize(); // Initial resize on component mount
      
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  
    const handlePrevClick = () => {
      setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
    };
  
    const handleNextClick = () => {
      setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
    };
  
    const handleTouchStart = (event) => {
      touchStartX.current = event.touches[0].clientX;
    };
  
    const handleTouchMove = (event) => {
      touchEndX.current = event.touches[0].clientX;
    };
  
    const handleTouchEnd = () => {
      if (touchStartX.current && touchEndX.current) {
        if (touchStartX.current - touchEndX.current > 50) {
          handleNextClick();
        } else if (touchEndX.current - touchStartX.current > 50) {
          handlePrevClick();
        }
      }
  
      touchStartX.current = null;
      touchEndX.current = null;
    };
  
    return (
      <div
        className="relative w-full"
        data-carousel="slide"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Carousel wrapper */}
        <div
          className="relative overflow-hidden"
          style={{ height: wrapperHeight }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={`${
                index === currentIndex ? 'block' : 'hidden'
              } duration-700 ease-in-out`}
              data-carousel-item
            >
              <img
                src={image}
                className="absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 object-cover"
                alt={`Slide ${index}`}
                />
            </div>
          ))}
        </div>
        {/* Slider indicators */}
        <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
          {images.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? 'bg-white' : 'bg-gray-200'
              }`}
              aria-current={index === currentIndex}
              aria-label={`Slide ${index + 1}`}
              data-carousel-slide-to={index}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>
        <button
    type="button"
    className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
    data-carousel-prev
    onClick={handlePrevClick}
  >
    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
      <svg
        aria-hidden="true"
        className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
      </svg>
      <span className="sr-only">Previous</span>
    </span>
  </button>
  <button
    type="button"
    className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
    data-carousel-next
    onClick={handleNextClick}
  >
    <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
      <svg
        aria-hidden="true"
        className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
      </svg>
      <span className="sr-only">Next</span>
    </span>
  </button>
</div>
);
};

export default Carousel;