import React from 'react';
import '../css/App.css';

function BottomBar() {
    return (
        <section className="py-[71px] px-4 md:px-24">
            <div className="justify-start mx-auto max-w-screen-xl">
                <div className="justify-start mb-25">
                    <ul className="grid">
                        <li className="justify-start">
                            <h2 className="text-xs text-left text-[#cfcfcf]">대표자: 전영철   회사명 : 세종탑머신   TEL : 010-6688-7907</h2>
                            <h2 className="text-xs text-left text-[#cfcfcf]">주소 : 충청북도 청주시 청원구 오창읍 후기길 41   E-mail : sjtop07@gmail.com     사업자번호 : 896-19-00325</h2>
                        </li>
                        <li>
                            <br />
                            <h2 className="text-xs text-left text-[#cfcfcf]"> Copyright ⓒ <a href='/'>세종탑머신</a>. All Rights Reserved.</h2>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
  
export default BottomBar;