import { useState, useEffect, useRef } from 'react';
import Iframe from 'react-iframe'
import Separator from "./Separator";
import Carousel from "./Carousel";

const images = [
    "/slideshow/slide%20(1).png",
    "/slideshow/slide%20(2).png",
    "/slideshow/slide%20(3).png",
    "/slideshow/slide%20(4).png",
    "/slideshow/slide%20(5).png",
    "/slideshow/slide%20(6).png",
    "/slideshow/slide%20(7).png",
];

const productList = [
  {
    id: 1,
    name: '15P-9',
    href: '#',
    imageSrc: '/electric/1.jpg',
    imageAlt: "35D/45D/50Dn-9VB",
    price: '가격문의',
    detail: '1.5톤',
  },
  {
    id: 2,
    name: '25/30/32/35B-X',
    href: '#',
    imageSrc: '/electric/2.png',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '2.5/3/3.2/3.5톤',
  },
  {
    id: 3,
    name: '15/18/20/25BR-X',
    href: '#',
    imageSrc: '/electric/3.png',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '1.5/1.8/2.0/2.5톤',
  },
  {
    id: 4,
    name: '16/18/20B-9F',
    href: '#',
    imageSrc: '/electric/4.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '1.6/1.8/2.0톤',
  },
  {
    id: 5,
    name: '22/25/30B-9F',
    href: '#',
    imageSrc: '/electric/5.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '2.0/2.5/3.0톤',
  },
  {
    id: 6,
    name: '40/45/50B-9',
    href: '#',
    imageSrc: '/electric/6.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '4.0/4.5/5.0톤',
  },
  {
    id: 7,
    name: '13/15/18/20/25BR-9',
    href: '#',
    imageSrc: '/electric/7.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '1.3/1.8/2.0/2.5톤',
  },
  {
    id: 8,
    name: '15/18/20BT-9U',
    href: '#',
    imageSrc: '/electric/8.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '1.5톤/1.8톤/2.0톤',
  }
]

function Products() {
  return (
    <div>
      <div className="mx-auto max-w-2xl lg:max-w-7xl ">
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {productList.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-80 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-200">
                  <a href={`/contact?msg=전동식 [${product.name}] 문의 요청드립니다.`}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-400">{product.detail}</p>
                </div>
                <p className="text-sm font-medium text-slate-200">{product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function MainMedia() {
  return (
    <div className="bg-transparent">
      <section className="mt-[70px]">
        <Carousel images={images} />
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
      <div className="justify-center mx-auto max-w-screen-xl pb-5">
      
                <h1 className="text-4xl font-bold text-left text-white">회사소개</h1>
                <p className="mt-[18px] text-[18px] text-left text-white">당사 홈페이지를 방문해주셔서 진심으로 감사드립니다.</p>
                <p className="mt-[18px] text-[18px] text-left text-white">귀사와 귀하의 무궁한 발전을 기원합니다. </p>
                <p className="mt-[18px] text-[18px] text-left text-white">세종탑머신은 1900년 중기계사업부로 사업을 시작하여 현재 굴삭기, 휠로다,지게차,스키드스티어로다,크레인 등 연간 0만 0천여대 규모의 건설장비 및</p>
                <p className="mt-[18px] text-[18px] text-left text-white">산업차량을 생산할 수 있는 0만여평 규모의 공장을 보유하고 있고 세계00개국 000개 딜러망과 미국,유럽,중국의 현지법인을 통해 활발한 영업활동을 펼쳐나가고 있습니다.</p>

                <p className="mt-[18px] text-[18px] text-left text-white">세종탑머신은 대전,세종,충남지역의 지게차판매 및 A/S를 담당하고</p>
                <p className="mt-[18px] text-[18px] text-left text-white">부가적으로 물류현장에서 지게차를 사용함에 따라 발생하는 모든업무 (중고,부품,렌탈,검사)를 종합적으로 처리할수 있는 지게차 전문지점입니다.</p>

                <p className="mt-[18px] text-[18px] text-left text-white">앞으로도 저희 세종탑머신은 풍부한 경험과 앞선 기술을 바탕으로 항상 고객을 먼저 생각할 수 있는 지점이 되도록 노력하고 있습니다.</p>
                <p className="mt-[18px] text-[18px] text-left text-white">감사합니다.</p>
            </div>
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
        <div className="justify-center mx-auto max-w-screen-xl">
          <div className='justify-between flex pb-5'>
            <h1 className="text-4xl font-bold text-left text-white">제품소개</h1>
          </div>
          <Products/>
            <div className="flex justify-center items-center mt-[42px]">
                <a href="/products" className="py-2 px-36 bg-black text-white rounded-md border border-white hover:bg-gray-800 focus:outline-none">
                    더보기
                </a>
            </div>
        </div>
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
        <div className="justify-center mx-auto max-w-screen-xl pb-5">
          <h1 className="text-4xl font-bold text-left text-white">원하는 기간만큼 사용하는 렌탈 지게차</h1>
          <div className="justify-between flex mt-[18px]">
            <p className="text-[18px] text-left text-white">세종탑머신에서는 필요한 기간에 맞춰서 지게차를 임대해 드립니다. 하루, 한 달, 혹은 5년이나 그 이상이라도 원하시는 만큼 임대 가능합니다. 또한 구체적인 일정 요건에 따라 단기와 장기 임대 옵션을 제공하며, 시즌별 피크, 재고 조사, 수주 잔량, 특별 프로젝트와 같은 다양한 상황에 대처할 수 있는 유연한 임대 기간도 제공합니다.</p>
            <img 
                src="items/1.jpg"
                className='pl-[32px] w-[500px] rounded-md'>
            </img>
          </div>
        </div>
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
        <div className="justify-center mx-auto max-w-screen-xl pb-5">
          <h1 className="text-4xl font-bold text-right text-white">성과를 높이는 믿을 수 있는 솔루션</h1>
          <div className="justify-between flex mt-[18px]">
            <img 
                src="items/2.jpeg"
                className='pl-[32px] w-[500px] rounded-md'>
            </img><p className="text-[18px] text-right text-white">세종탑머신의 특징 중 하나는 지속적인 기술 혁신과 향상입니다. 모든 현대지게차 임대 차량은 최신 기술과 장비로 구성되어 있으며, 고객의 요구에 맞게 맞춤형 서비스를 제공합니다. 현대지게차는 고객의 성공을 위해 최선을 다할 것을 약속드립니다.</p>
            
          </div>
          {/* <p className="mt-[18px] text-[18px] text-right text-white">현대지게차의 특징 중 하나는 지속적인 기술 혁신과 향상입니다. 모든 현대지게차 임대 차량은 최신 기술과 장비로 구성되어 있으며, 고객의 요구에 맞게 맞춤형 서비스를 제공합니다. 현대지게차는 고객의 성공을 위해 최선을 다할 것을 약속드립니다.</p> */}
        </div>
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
        <div className="justify-center mx-auto max-w-screen-xl pb-5">
          <h1 className="text-4xl font-bold text-left text-white">오시는 길</h1>
          <div className="mt-[18px] ">
            <Iframe  
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313884.408539903!2d125.23356349199157!3d35.794648845554924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356455ebcb11ba9b%3A0x91249b00ba88db4b!2z64yA7ZWc66-86rWt!5e0!3m2!1sko!2skr!4v1683297822960!5m2!1sko!2skr"
                width="100%" 
                height="450" 
                style="border:0;" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </Iframe>
          </div>
        </div>
      </section>
      <Separator />
      <section className="mt-[140px] mb-[140px] p-4">
        <div className="justify-center mx-auto max-w-screen-xl pb-5">
          <h1 className="text-4xl font-bold text-center text-white">전화번호</h1>
          <p className="mt-[18px] text-[18px] text-center text-white">010.6688.7907</p>
        </div>
      </section>
      <Separator />
    </div>
  );
}

export default MainMedia;
