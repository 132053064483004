import { useState, useEffect, useRef } from 'react';
import Separator from "./Separator";
import SelectMenu from './SelectMenu';

const selectList = [
  {
    id: 1,
    name: '디젤식',
  },
  {
    id: 2,
    name: '전동식',
  },
  {
    id: 3,
    name: '스키드로더',
  },
  {
    id: 4,
    name: 'LPG식',
  }
]

const productListElectric = [
    {
      id: 1,
      name: '15P-9',
      href: '#',
      imageSrc: '/electric/1.jpg',
      imageAlt: "35D/45D/50Dn-9VB",
      price: '가격문의',
      detail: '1.5톤',
    },
    {
      id: 2,
      name: '25/30/32/35B-X',
      href: '#',
      imageSrc: '/electric/2.png',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '2.5/3/3.2/3.5톤',
    },
    {
      id: 3,
      name: '15/18/20/25BR-X',
      href: '#',
      imageSrc: '/electric/3.png',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1.5/1.8/2.0/2.5톤',
    },
    {
      id: 4,
      name: '16/18/20B-9F',
      href: '#',
      imageSrc: '/electric/4.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1.6/1.8/2.0톤',
    },
    {
      id: 5,
      name: '22/25/30B-9F',
      href: '#',
      imageSrc: '/electric/5.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '2.0/2.5/3.0톤',
    },
    {
      id: 6,
      name: '40/45/50B-9',
      href: '#',
      imageSrc: '/electric/6.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '4.0/4.5/5.0톤',
    },
    {
      id: 7,
      name: '13/15/18/20/25BR-9',
      href: '#',
      imageSrc: '/electric/7.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1.3/1.8/2.0/2.5톤',
    },
    {
      id: 8,
      name: '15/18/20BT-9U',
      href: '#',
      imageSrc: '/electric/8.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1.5톤/1.8톤/2.0톤',
    },
    {
      id: 9,
      name: '40T-9',
      href: '#',
      imageSrc: '/electric/9.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '4.0톤',
    },
    {
      id: 10,
      name: '10 / 13BOP-7',
      href: '#',
      imageSrc: '/electric/10.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1톤~1.3톤',
    },
    {
      id: 11,
      name: '13BOP-9',
      href: '#',
      imageSrc: '/electric/11.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1.3톤',
    },
    {
      id: 12,
      name: '자율주행지게차 RS010A-9',
      href: '#',
      imageSrc: '/electric/12.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '가격문의',
      detail: '1톤',
    },
]

const productListDiesel = [
  {
    id: 1,
    name: '35D/45D/50Dn-9VB',
    href: '#',
    imageSrc: '/diesel/1.png',
    imageAlt: "35D/45D/50Dn-9VB",
    price: '가격문의',
    detail: '3.5/4.5/5톤',
  },
  {
    id: 2,
    name: '25/30D-9V,35DN-9V',
    href: '#',
    imageSrc: '/diesel/2.png',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '2.5/2.9/3.5톤',
  },
  {
    id: 3,
    name: '70/80D-9V',
    href: '#',
    imageSrc: '/diesel/3.png',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '7/8톤',
  },
  {
    id: 4,
    name: '300D-9V',
    href: '#',
    imageSrc: '/diesel/4.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '30톤',
  },
  {
    id: 5,
    name: '250D-9V',
    href: '#',
    imageSrc: '/diesel/5.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '25톤',
  },
  {
    id: 6,
    name: '180D-9V',
    href: '#',
    imageSrc: '/diesel/6.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '18톤',
  },
  {
    id: 7,
    name: '100D-9V',
    href: '#',
    imageSrc: '/diesel/7.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '10톤',
  },
  {
    id: 8,
    name: '110/130/160D-9V',
    href: '#',
    imageSrc: '/diesel/8.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '11/13/16톤',
  },
  {
    id: 9,
    name: '70D-9VB',
    href: '#',
    imageSrc: '/diesel/9.png',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '7톤',
  },
]

const productListSkidLoader = [
  {
    id: 1,
    name: 'HSL550-7',
    href: '#',
    imageSrc: '/SkidLoader/1.jpg',
    imageAlt: "35D/45D/50Dn-9VB",
    price: '가격문의',
    detail: '36.7마력',
  },
  {
    id: 2,
    name: 'HS120V/HS80V',
    href: '#',
    imageSrc: '/SkidLoader/2.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '가격문의',
    detail: '1.220KG',
  },
]

const productListLPG = [
  {
    id: 1,
    name: '25/30/33L-7A',
    href: '#',
    imageSrc: '/LPG/1.PNG',
    imageAlt: "35D/45D/50Dn-9VB",
    price: '가격문의',
    detail: '2.5톤~3.3톤',
  }
]

function Products({productList, category}) {
  return (
    <div>
      <div className="mx-auto max-w-2xl lg:max-w-7xl ">
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {productList.map((product) => (
            <div key={product.id} className="group relative">
              <div className="min-h-80 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-200">
                    <a href={`/contact?msg=${category} [${product.name}] 문의 요청드립니다.`}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-400">{product.detail}</p>
                </div>
                <p className="text-sm font-medium text-slate-200">{product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function ProductView() {
  const [selected, setSelected] = useState(selectList[0])
  const [productList, setProductList] = useState(productListDiesel)

  useEffect(() => {
    if (selected.id === 1) {
      setProductList(productListDiesel)
    }
    else if (selected.id === 2) {
      setProductList(productListElectric)
    }
    else if (selected.id === 3) {
      setProductList(productListSkidLoader)
    }
    else if (selected.id === 4) {
      setProductList(productListLPG)
    }
}, [selected]);

  return (
    <div className="bg-transparent">
      <Separator />
      <section className="mt-[70px] mb-[140px] p-4">
      <div className="isolate px-6 lg:px-8">
        <div
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          aria-hidden="true"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
        <div className="justify-center mx-auto max-w-screen-xl">
          <div className='justify-between flex'>
            <h1 className="text-4xl font-bold text-left text-white">제품소개</h1>
            <SelectMenu people={selectList} setSelected={setSelected} selected={selected}/>
          </div>
          <Products productList={productList} category={selected.name}/>
        </div>
      </section>
      <Separator />
    </div>
  );
}

export default ProductView;